import styled from "styled-components";

const ServicesWrapper = styled.div`

.swiper.swiper-initialized {
  padding-bottom: 30px;
  margin-top: 35px;
}

.cardbg {
  background-size: cover !important;
  background-repeat: no-repeat;
  height: 317px;
  width: 100%;
  display: flex;
    align-items: center;
    padding: 0 35px;

  h2 {
    color: #ffffff;
    font-size: 24px;
    margin-bottom: 35px;
    line-height: 1.4;
    max-width: 240px;
  }

  a.btncard {
    border: 1px solid #ffffff;
    padding: 10px 20px;
    color: #ffffff;
  }
}

  .serviceCardNew h2 {
    font-size: 18px;
    color: #000000;
  }

  .title-bar-breadcrumb a {
    color: #fff;
    text-decoration: unset;
  }
  .title-bar-breadcrumb a:hover {
    text-decoration: underline;
  }
  .services-page {
    width: 100%;
  }
  .services-page .section-container {
    width: 100%;
    max-width: 1200px;
    z-index: 1;
    margin: auto;
    padding: 20px;
  }
  .services-page section {
    display: inline-flex;
    width: 100%;
  }
  .services-page .title-bar {
    background-image: url("/services.png");
    text-align: center;
    height: 460px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;
  }
  .services-page .title-bar-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.46);
    width: 100%;
    height: 460px;
    z-index: 0;
  }
  .services-page .title-bar-heading {
    font-size: 40px;
    font-weight: 500;
    line-height: 46px;
    margin: 0;
  }
  .services-page .title-bar-breadcrumb {
    margin: 0;
    margin-top: 10px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
  }
  .services-page .title-bar-separator {
    position: relative;
    bottom: 60px;
  }
  .services-page .title-bar-separator img.separator-image {
    width: 100%;
  }
  .services-page .content-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
  .services-page .full-width {
    width: 100%;
  }
  .services-page .colored-sub-title {
    font-size: 20px;
    line-height: 24px;
    color: #a8600e;
    font-weight: 400;
    margin: 0;
    margin-bottom: 10px;
  }
  .services-page .title {
    font-size: 28px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    margin-top: 0;
  }
  .content-grid-1 {
    display: grid;
    grid-template-columns: 1.25fr 2fr;
    gap: 30px;
    align-items: center;
  }
  .offer-container-content h3,
  .offer-container-content h2 {
    color: #ffffff;
  }
  .serviceCardNew p {
    font-size: 16px;
    line-height: 1.6;
    color: #000000;
  }
  .serviceCardNew img {
    width: 270px;
    height: 230px;
    object-fit: cover;
    border-top-right-radius: 30px;
    border: 1px solid #dedede;
  }
  .serviceCardNew .col-md-6 a {
    background: #d89140;
    color: #ffffff;
    padding: 7px 16px;
    font-size: 14px;
    border-radius: 4px;
  }
  .serviceCardNew {
    display: block !important;
    position: relative;
  }
  .serviceCardNew .col-md-6 {
    width: 46%;
  }
  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #c98202 !important;
    width: 16px;
    border-radius: 7px;
  }
  .services-page .title-big {
    font-size: 55px;
    line-height: 65px;
    font-weight: 600;
    color: #000;
    margin: 0;
  }
  .services-section-three {
    background: #f4f3f3;
    padding: 20px 0;
  }
  .services-section-two {
    padding: 20px 0;
  }
  .services-section-four {
    text-align: center;
    padding: 20px 0;
  }

  .offer-container .title-1,
  .offer-container .title-3 {
    font-size: 20px;
    line-height: 30px;
    margin: 10px 0;
    font-weight: 400;
  }

  .offer-container {
    width: 100%;
    max-width: 920px;
    margin: auto;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .offer-container-content {
    background: #00000080;
    height: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .offer-container .title-2 {
    font-size: 70px;
    font-weight: 400;
    margin: 10px 0;
  }
  .offer-container .big-title {
    font-size: 90px;
    font-weight: 700;
  }
  .border-button {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-decoration: unset;
    border: 1px solid #fff;
    padding: 18px 30px;
    margin-top: 30px;
  }
  .border-button:hover {
    background: #a8600e;
  }

  .swiper-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-bottom: 40px;
  }
  .swiper-wrapper {
    display: flex;
    transition: transform 0.5s ease;
  }
  .swiper-slide {
    flex: none;
    background-size: cover;
    background-position: center;
    position: relative;
    min-height: 360px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .slide-title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .slide-button {
    font-size: 16px;
    text-transform: capitalize;
    line-height: 20px;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    text-decoration: unset;
    padding: 12px 25px;
    display: inline-block;
  }
  .swiper-bullet {
    width: 10px;
    height: 10px;
    background-color: #373737;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: width 0.3s ease;
  }
  .slide-button:hover {
    background: #a8600e;
  }
  .swiper-bullet-active {
    width: 20px;
    background-color: #a8600e;
  }
  .slide-content {
    width: 40%;
    padding: 30px;
    color: #fff;
  }
  .swiper-pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-bullet {
    width: 10px;
    height: 10px;
    background-color: #373737;
    margin: 0 5px;
    border-radius: 100px;
    cursor: pointer;
    transition: width 0.3s ease;
  }
  .swiper-bullet-active {
    width: 25px;
    background-color: #a8600e;
  }
  .swiper-bullet:hover {
    background-color: #a8600e;
  }

  .headB h3 {
    text-align: left !important;
  }
  .headB p {
    margin-bottom: 10px;
    color: #cc8739;
  }

  @media only screen and (min-device-width: 767px) {
  }

  @media only screen and (max-device-width: 1024px) {
    .services-page .title-bar-separator {
      position: relative;
      bottom: 48px !important;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .slide-content {
      width: 65% !important;
      padding: 20px !important;
    }
  }

  @media only screen and (max-device-width: 768px) {

    .cardbg {
      height: 210px;
      padding: 0 15px;

      h2 {
        max-width: 160px;
    }
    }

    .content-area-center > h2 {
      margin-bottom: 40px;
    }
    .swiper.swiper-initialized {
      padding-bottom: 40px;
    }
    .swiper-pagination {
      transform: translateX(0%);
    }
    .serviceCardNew .row .col-md-6:nth-child(1) {
      order: 2;
      margin-top: 30px;
    }
    .serviceCardNew .col-md-6 {
      width: 100%;
    }
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
    .offer-container .big-title {
      font-size: 40px;
      font-weight: 700;
    }
    .offer-container .title-2 {
      font-size: 24px;
    }
    .offer-container-content {
      background: #000000bd;
      height: 340px;
    }
    .services-page .section-container {
      padding: 10px;
    }
    .serviceCardNew img {
      width: 100%;
    }
    .services-page .title-bar-separator {
      bottom: 17px !important;
    }

    .content-grid-1 {
      grid-template-columns: 100% !important;
      text-align: center !important;
      gap: 15px !important;
    }

    .swiper-slide {
      min-height: 260px;
    }
    .services-page .content-grid {
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 30px;
    }
    .section-gray-bg {
      background: none !important;
      padding: 0 !important;
    }
  }
  @media only screen and (max-device-width: 767px) {
  }
`;

export default ServicesWrapper;
